<template>
  <div class="videoapp">
    <notifications position="bottom right" />

    <div v-if="isError" class="videoapp__error">
      <!-- ERROR -->
      <span class="emoji">😑</span>
      <h1>
        We are sorry!<br />
        Something went wrong.
      </h1>
      <div class="uk-alert-danger" uk-alert>
        {{ isError }}
      </div>
      <p>If this error persists, please contact us.</p>
      <p>
        <a href="mailto:apps@visionsbox.de">apps@visionsbox.de</a>
        <br />
        <a href="tel:+49 7803 9789030">+49 7803 9789030</a>
      </p>
    </div>
    <div v-else-if="isLoading" class="videoapp__loading">
      <!-- LOADER -->
      <div>
        <i uk-spinner="ratio: 3"></i>
      </div>
      <p>Loading ...</p>
    </div>
    <template v-else>
      <div class="videoapp__main" id="app">
        <!-- APP -->
        <div id="page-wrap">
          <transition
            name="fade"
            enter-active-class="fadeInDown"
            leave-active-class="fadeOutDown"
          >
            <header
              class="videoapp__header"
              v-if="currentLocationId && currentLocationId != 21"
            >
              <a
                class="videoapp__logo"
                href="/"
                :title="strings.app_title"
                v-if="settings.logo"
              >
                <img
                  :src="_vaData.apiBase + settings.logo"
                  :alt="strings.app_title"
                />
              </a>
              <div class="subtitle">
                <transition
                  name="fade"
                  enter-active-class="fadeInRight"
                  leave-active-class="fadeOutRight"
                >
                  <div v-if="locationTitle">
                    <h1>{{ locationTitle }}</h1>
                  </div>
                </transition>
              </div>
              <Title :showBackBtn="showBackBtn"></Title>
            </header>
          </transition>
          <Content />

          <div class="videoapp__videothing-wrap">
            <VideoThing :settings="vtSettings" />
          </div>
          <ContactMenu class="videoapp__contact hide_mobile" />
          <footer class="videoapp__footer">
            <div uk-grid class="uk-flex-bottom">
              <div class="uk-flex-1">
                <div uk-grid class="uk-grid-small">
                  <UkNavDropdown
                    v-if="locationsMenuEntries.length > 1"
                    :entries="locationsMenuEntries"
                    :title="strings.locations"
                    @onEntryClick="onDropDownNavEntryClick"
                  />
                  <a
                    href="/?location=1"
                    class="uk-button uk-button-primary home-btn"
                  ></a>
                  <a
                    :href="strings.homepage"
                    class="uk-button uk-button-primary homepage-btn"
                    onclick="dataLayer.push({'event': 'homepage-button-click'});"
                    target="_blank"
                  ></a>
                  <button
                    v-if="strings.memory_game_link"
                    class="uk-button uk-button-primary memory_game"
                    @click="showMemoryGame"
                    onclick="dataLayer.push({'event': 'play-button-click'});"
                  >
                    <i class="memory-btn"></i>
                    {{ strings.memory_game }}
                  </button>

                  <button
                    v-if="strings.dishdash_game_link"
                    class="uk-button uk-button-primary dishdash_game"
                    @click="showGame(strings.dishdash_game_link || '')"
                  >
                    <i class="dishdash-btn"></i>
                    {{ strings.dishdash_game }}
                  </button>

                  <Footer :showBackBtn="showBackBtn"></Footer>
                </div>
              </div>
            </div>
          </footer>

          <MobileUi />
        </div>
      </div>
      <Offcanvas v-if="currentLocationId && currentLocationId != 21" />
    </template>
  </div>
</template>

<script>
import VideoThing from "./components/videoThing/VideoThing.vue";
import UkNavDropdown from "./components/UkNavDropdown.vue";
import Offcanvas from "./components/Offcanvas.vue";
import Content from "./components/content/index.vue";
import Title from "./components/Title.vue";
import Footer from "./components/Footer.vue";
import MobileUi from "./components/mobileUi/MobileUi.vue";
import ContactMenu from "./components/ContactMenu.vue";
import MemoryGame from "./components/MemoryGame.vue";
import Game from "./components/Game.vue";

import { bus } from "./main";

export default {
  name: "App",
  components: {
    VideoThing,
    UkNavDropdown,
    Content,
    Title,
    Footer,
    MobileUi,
    ContactMenu,
    Offcanvas,
  },
  metaInfo() {
    return {
      title: this.strings ? this.strings.app_title : "Loading ...",
    };
  },
  data: function() {
    return {
      settings: this._vaData.settings,
      strings: this._vaData.strings,
      isLoading: true,
      isError: false,
      vtSettings: false,
      locationTitle: false,
      locationHotspots: false,
      showBackBtn: false,
      currentLocationId: null,
    };
  },
  computed: {
    contentMenuEntries: function() {
      return this._vaData.contents
        ? this._vaData.contents.filter((c) => c.show_in_menu)
        : false;
    },
    locationsMenuEntries: function() {
      return this._vaData.locations
        ? this._vaData.locations.filter((l) => l.show_in_menu)
        : false;
    },
  },
  watch: {
    // https://stackoverflow.com/a/46331968/1308363
    "settings.show_hotspots": {
      handler(val) {
        if (val) {
          bus.$emit("show_hotspots");
        } else {
          bus.$emit("hide_hotspots");
        }
      },
    },
    "settings.show_navigation": {
      handler(val) {
        if (val) {
          bus.$emit("show_navigation");
        } else {
          bus.$emit("hide_navigation");
        }
      },
    },
  },
  methods: {
    async fetchData() {
      const apiBase = process.env.VUE_APP_API_BASE;
      this.isLoading = true;

      console.log("🔄 Loading data ...");
      const availableLanguages = ["de", "en"];
      const params = new URLSearchParams(window.location.search);
      const langParameter = params.get("lang");
      let lang = "en";

      if (langParameter) {
        lang =
          availableLanguages.indexOf(langParameter) > -1 ? langParameter : lang;
      } else {
        // try user lang
        var userLang = navigator.language || navigator.userLanguage;
        userLang = userLang.split("-")[0];

        lang = availableLanguages.indexOf(userLang) > -1 ? userLang : lang;
      }

      console.log("🏳️‍🌈 Language: " + lang);
      // set document lang (html attribute)
      document.documentElement.lang = lang.split("-")[0];
      this._vaData.currentLanguage = lang;

      await Promise.all([
        fetch(apiBase + "/locations/" + lang),
        fetch(apiBase + "/destinations"),
        fetch(apiBase + "/settings"),
        fetch(apiBase + "/products/" + lang),
        fetch(apiBase + "/strings/" + lang),
      ])
        .then((data) => {
          let failedRequests = data.filter((d) => d.ok === false);

          if (failedRequests.length) {
            let error = "Some API calls failed.";
            throw Error(error);
          }

          return data;
        })
        .then((data) => {
          return Promise.all(data.map((d) => d.json()));
        })
        .then((data) => {
          this._vaData.locations = this.locations = data[0];
          this._vaData.destinations = this.destinations = data[1];
          this._vaData.settings = this.settings = data[2];
          this._vaData.contents = this.contents = data[3];
          this._vaData.strings = this.strings = data[4];

          console.log("👨🏼‍🔧 Settings", this._vaData.settings);
          console.log("🏝️ Locations: ", this._vaData.locations);
          console.log("✈️ Destinations: ", this._vaData.destinations);
          console.log("📚 Contents: ", this._vaData.contents);
          console.log("📄 Strings: ", this._vaData.strings);

          if (!this.locations.length) {
            // this.isError = 'No location found!';
            throw new Error("No location found!");
          }

          this.main();
        })
        .catch((err) => {
          // api not responding
          this.isError = err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    main() {
      this.init();
      this.showContent();
      this.tagManager();
    },
    init() {
      console.log("📽️ Starting videoThing");
      const params = new URLSearchParams(window.location.search);
      const defaultStartLocation = this.settings.start_location
        ? this.settings.start_location
        : this._vaData.locations[0].id;
      const startLocation = params.get("location")
        ? parseInt(params.get("location"))
        : defaultStartLocation;

      this.vtSettings = {
        startLocation: startLocation,
        screensaver: null,
        preloadDestinationsAtStart: false,
        assetBase: process.env.VUE_APP_API_BASE,
        transitionOverlay: {
          color: "#fff",
          transitionDuration: 1000,
        },
      };

      // bus.$emit('location_reached', this.settings.start_location, []);
      window.addEventListener("load", function() {
        setTimeout(function() {
          // This hides the address bar:
          window.scrollTo(0, 1);
        }, 0);
      });
    },
    showContent() {
      const params = new URLSearchParams(window.location.search);
      const content = params.get("content");

      if (content) {
        bus.$emit("show_content", content);
      }
    },
    tagManager() {
      // TAGMANAGER EVENTS
      bus.$on("location_reached", (id) => {
        const location = this._vaData.locations.find((l) => l.id === id);
        const data = {
          event: "location_reached",
          id: location.id,
          title: location.title,
        };
        if (this.isDev) console.info("TM:location_reached", data);

        window.dataLayer?.push(data);
      });

      bus.$on("content_opened", (id) => {
        if (
          typeof id === "string" &&
          (id.startsWith("https://") || id.startsWith("http://"))
        ) {
          // Open iFrame
          const data = {
            event: "content_opened",
            id: id,
            title: `iFrame: ${id}`,
          };
          if (this.isDev) console.info("TM:content_opened", data);

          window.dataLayer?.push(data);
        } else {
          // Open content modal
          const content = this._vaData.contents.find((d) => {
            return d.id == id;
          });
          const data = {
            event: "content_opened",
            id: content.id,
            title: content.title,
          };
          if (this.isDev) console.info("TM:content_opened", data);

          window.dataLayer?.push(data);
        }
      });

      bus.$on("offcanvas_opened", () => {
        const data = {
          event: "offcanvas_opened",
        };
        if (this.isDev) console.info("TM:offcanvas_opened", data);
        window.dataLayer?.push(data);
      });

      bus.$on("contact_form_opened", () => {
        const data = {
          event: "contact_form_opened",
        };
        if (this.isDev) console.info("TM:contact_form_opened", data);
        window.dataLayer?.push(data);
      });

      bus.$on("slide_showed", (data) => {
        data.event = "slide_showed";
        if (this.isDev) console.info("TM:slide_showed", data);
        window.dataLayer?.push(data);
      });

      bus.$on("video_started", (data) => {
        data.event = "video_started";
        if (this.isDev) console.info("TM:video_started", data);
        window.dataLayer?.push(data);
      });
      bus.$on("video_paused", (data) => {
        data.event = "video_paused";
        if (this.isDev) console.info("TM:video_paused", data);
        window.dataLayer?.push(data);
      });
    },

    onDropDownNavEntryClick(entry) {
      switch (entry.type) {
        case "default":
          bus.$emit("show_content", entry.id);
          break;
        case "media":
          bus.$emit("show_content", entry.id);
          break;
        case "hotspot":
          bus.$emit("show_content", entry.target_content.id);
          break;
        case "menu":
          bus.$emit("show_button_menu", null, entry.menu_data);
          break;
        default:
          bus.$emit("go_to_location", entry.id);
          break;
      }
    },
    showMemoryGame: function() {
      this.$modal.show(
        MemoryGame,
        { classes: `c-va_modal c-va_modal--memory` },
        {
          name: "content-modal",
          classes: `c-va_modal c-va_modal--memory`,
          height: "auto",
          top: "10px",
          // adaptive: true,
          scrollable: true,
        }
      );
    },
    showGame: function(url) {
      this.$modal.show(
        Game,
        { classes: `c-va_modal c-va_modal--game`, url },
        {
          name: "content-modal",
          classes: `c-va_modal c-va_modal--game`,
          height: "auto",
          top: "10px",
          // adaptive: true,
          scrollable: true,
        }
      );
    },
  },
  async created() {
    bus.$on("location_reached", (id, history) => {
      const location = this._vaData.locations.find((l) => l.id === id);
      console.info(
        `➡️ You reached location: ${location.title} (ID: ${id})`,
        location
      );
      this.locationTitle = location.title;

      this.locationHotspots = location.buttons.filter(
        (b) => b.type === "hotspot"
      );
      setTimeout(() => {
        this.showBackBtn = history.length > 1 && location.show_back_btn;
      }, 1);

      const params = new URLSearchParams(window.location.search);
      params.set("location", id);
      window.history.replaceState({}, "", `?${params}`);

      this.currentLocationId = location.id;
    });

    bus.$on("location_left", (id) => {
      const location = this._vaData.locations.find((l) => l.id === id);
      console.info(`⬅️ You left location: ${location.title} (ID: ${id})`);
      this.locationHotspots = false;
      this.showBackBtn = false;
    });

    await this.fetchData();

    const params = new URLSearchParams(window.location.search);
    const defaultStartContent = this.settings.start_content
      ? this.settings.start_content
      : null;
    const startContent = params.get("content")
      ? parseInt(params.get("content"))
      : defaultStartContent;

    if (startContent) {
      bus.$emit("show_content", startContent);
    }
  },

  mounted() {},
};
</script>

<style lang="scss">
$animationDuration: 0.5s;
// specify animation duration. Default value: 1s
@import "@/../node_modules/vue2-animate/src/sass/vue2-animate.scss";
@import "./videoapp.scss";
@import "./zammad.scss";

body {
  &.is-mobile {
    @media screen and (orientation: landscape) {
      position: relative;

      &:before {
        content: "";
        z-index: 99999;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $global-emphasis-color;
      }

      &:after {
        content: "";
        position: absolute;
        top: calc(50% - 100px);
        left: calc(50% - 100px);
        width: 200px;
        height: 200px;
        background-image: url("./assets/rotate.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        z-index: 99999;
      }
    }
    .dishdash_game,
    .memory_game {
      // animation: wiggle 2s 3;
      position: absolute;
      bottom: 45px;
      left: 0px;
      margin: 0;
    }
  }
}

.home-btn {
  width: 2em;
  margin-left: 0.5em;
  background-image: url("./assets/haus.svg");
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
  pointer-events: all;

  @media (max-width: 960px) {
    background-size: 45%;
    padding-left: 5px;
  }
}
.homepage-btn {
  width: 2em;
  margin-left: 0.5em;
  background-image: url("./assets/world.svg");
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
  pointer-events: all;
  @media (max-width: 960px) {
    background-size: 45%;
    padding-left: 5px;
  }
}
.memory-btn {
  margin-left: 0.4em;
  margin-right: 0.4em;
  background-image: url("./assets/icon-euro.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 95%;
  pointer-events: all;
  padding: 18px;
  filter: invert(1);
  @media (max-width: 960px) {
    // display: none;
  }
}
.dishdash_game,
.memory_game {
  font-size: 16px;
  margin-left: 0.5em;
  pointer-events: all;
  animation: stretch 2s 5;
}
@keyframes stretch {
  0% {
    transform: scale(1);
    background-color: #264a90;
  }
  50% {
    transform: scale(1.5);
    background-color: #da193b;
  }
  100% {
    transform: scale(1);
    background-color: #264a90;
  }
}
@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(5deg);

    background-color: #da193b;
  }
  95% {
    transform: rotate(-5deg);

    background-color: #da193b;
  }
  100% {
    transform: rotate(0deg);
    background-color: #da193b;
  }
}

.uk-dropdown {
  pointer-events: all;
  background: $global-emphasis-color;
  color: #fff;

  button {
    pointer-events: all;
  }
}
.footer-btn {
  @media (max-width: 960px) {
    line-height: 20px;
  }
}
.subtitle {
  // margin-top: -.5em;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.5);
  @media (orientation: portrait) and (max-width: $breakpoint-large) {
    display: none;
  }
  @media (orientation: landscape) and (max-width: $breakpoint-large) {
    margin-top: 1em;
    font-size: 11px;
  }
  @media (max-width: 960px) {
    margin-top: 1em;
    margin-left: 0;
    padding-left: 0;
  }
  margin-left: 1em;
  padding-left: 1em;
  margin-top: 1em;
}
h1 {
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  font-size: 1.8em;
  margin-top: -0.2em;
  @media (max-width: 960px) {
    font-size: 1.6em;
  }
}
</style>

<template>
  <div class="va_modal__game" @click.self="$emit('close')">
    <button class="vm-modal__close" @click="$emit('close')">
      <span uk-icon="icon: close; ratio: 1.5"></span>
    </button>
    <div class="slide__media">
      <template>
        <div class="media">
          <div
            class="vx-ratio-box mobile_fullscreen vx-ratio-box-1-1 uk-background-muted"
          >
            <iframe :src="url" frameborder="0"></iframe>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Game",
  props: {
    url: String,
  },
};
</script>

<style scoped lang="scss">
body {
  &.is-mobile {
    .vm-modal__close {
      top: 2.5%;
      right: 6%;
    }
  }
}
.title {
  @extend h2;
}
.mobile_fullscreen {
  padding-top: 0 !important;
  height: 100%;
}
.slide__media {
  height: 100%;
}
.media {
  height: 100%;
}

.vm-modal__close {
  top: 6.5%;
  right: 6%;
}
.vm--overlay {
  pointer-events: none;
}
.va_modal__game {
  overflow: hidden;
  padding: 2.5% 5% !important;
  max-width: 90% !important;
  width: 90% !important;
  height: 95% !important;
  max-height: 90% !important;
}
</style>
